import axios from '../utils/axios'

export function register(params) {
    return axios.post(`/wxh5/product/register`, params);
}

export function saveAfterService(params) {
    return axios.post(`/wxh5/product/saveAfterService`, params);
}

export function getProductInfo(uuid) {
    return axios.post(`/wxh5/product/getProductInfo`, { 'uuid': uuid, 'codeId': uuid });
}