import axios from 'axios'
import { Toast } from 'vant'
import { getQueryString } from '@/common/js/utils'

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '//127.0.0.1:8080' : ''
axios.defaults.withCredentials = false
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['openid'] = String(localStorage.getItem('openid'))
axios.defaults.headers['uuid'] = getQueryString('uuid')

axios.interceptors.response.use(res => {
    if (typeof res.data !== 'object') {
        Toast.fail('服务端异常！')
        return Promise.reject(res)
    }
    if (res.data.code != 200) {
        if (res.data.message) {
            Toast.fail(res.data.message)
        }
        return Promise.reject(res.data)
    }
    return res.data
})

export default axios