<template>
  <div>

    <van-form @submit="onSubmit">
      <div class="product">

        <div class="product-info">
          <div class="info-item">
            <label>产品名称：</label>
            <span>{{product.productDesc}}</span>
          </div>
          <div class="info-item">
            <label>产品型号：</label>
            <span>{{product.productTypeDesc}}</span>
          </div>
          <div class="info-item">
            <label>购买日期：</label>
            <span>{{product.buyDate}}</span>
          </div>
        </div>

        <div class="service-info">
            <van-field name="radio" label="服务类型">
              <template #input>
                <van-radio-group v-model="registerInfo.asType" direction="horizontal">
                  <van-radio name="1" v-if="product.workCodeInstall">报装</van-radio>
                  <van-radio name="2" v-if="product.workCodeRepaire">报修</van-radio>
                </van-radio-group>
              </template>
            </van-field>

            <van-field
                v-model="registerInfo.customerName"
                name="customerName"
                label="顾客姓名"
                placeholder="请填写"
                :rules="[{ required: true, message: '请填写顾客姓名' }]"
            />

            <van-field
                v-model="registerInfo.customerTel"
                name="customerTel"
                label="移动电话"
                placeholder="请填写"
                type="tel"
                :rules="[
                  { required: true, message: '请填写移动电话' }
                ]"
                clearable
            />

            <van-field
                v-model="registerInfo.addressPca"
                readonly
                clickable
                name="addressPca"
                label="地区选择"
                placeholder="点击选择省市区"
                @click="showAddressArea = true"
                :rules="[{ required: true, message: '请填写省市区' }]"
            />
            <van-popup v-model="showAddressArea" position="bottom">
              <van-area
                  :area-list="areaList"
                  @confirm="onConfirmAddress"
                  @cancel="showAddressArea = false"
              />
            </van-popup>

            <van-field
                v-model="registerInfo.address"
                name="address"
                label="详细地址"
                placeholder="请填写"
                :rules="[{ required: true, message: '请填写详细地址' }]"
            />

            <van-field
                v-model="registerInfo.srvMemo"
                rows="2"
                autosize
                label="备注信息"
                type="textarea"
                maxlength="100"
                placeholder="请输入备注信息"
                show-word-limit
            />
        </div>

        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </div>

    </van-form>
  </div>
</template>

<script>
import { getProductInfo, saveAfterService } from '../service/product'
import { getOpenID } from '../service/common'
import { getWxInfo } from '../service/index'
import { Toast } from 'vant'
import { getLocal, getQueryString, setLocal, tdist} from '@/common/js/utils'
export default {
  components: {
  },
  name: 'home',
  data() {
    return {
      isWinXinClient: false,
      isLogin: false,
      headerScroll: false,

      product:{
        brandDesc: '',
        productCode : '',
        productDesc : '',
        productTypeDesc : '',
        hasRegisted : '1',
        registeDate : '',
        buyDate : '',
        unifiedRetailPrices : '',
        workCodeInstall: true,
        workCodeRepaire: true
      },

      registerInfo:{
        asType:'1',
        customerName:'',
        customerTel:'',
        addressPca:'',
        provinceCode:'',
        provinceName:'',
        cityCode:'',
        cityName:'',
        streetCode:'',
        streetName:'',
        address:'',
        buyDate:'',
        srvMemo:''
      },

      showAddressArea: false,
      showBuyDate: false,

      areaList: {
        province_list: {},
        city_list: {},
        county_list: {}
      },

      dateValue: '',
      imageValue: ''
    }
  },
  async mounted() {
    // 省市区列表构造
    let _province_list = {}
    let _city_list = {}
    let _county_list = {}
    tdist.getLev1().forEach(p => {
      _province_list[p.id] = p.text
      tdist.getLev2(p.id).forEach(c => {
        _city_list[c.id] = c.text
        tdist.getLev3(c.id).forEach(q => _county_list[q.id] = q.text)
      })
    })
    this.areaList.province_list = _province_list
    this.areaList.city_list = _city_list
    this.areaList.county_list = _county_list

    var that = this;
    var env = process.env.NODE_ENV;
    const ua = window.navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i) == 'micromessenger' || env == 'development'){
      this.isWinXinClient = true;
    }else{
      this.isWinXinClient = false;
      Toast.fail('请在微信扫码打开')
      return;
    }

    var uuid = getQueryString('uuid');
    if(!uuid || uuid == null || uuid == 'null'){
      Toast.fail('请重新扫码打开')
      return;
    }
    setLocal('uuid', String(uuid));

    const wxInfo = await getWxInfo();
    console.log('-------3.1--------');
    console.log(wxInfo);
    console.log('-------3.2--------');
    this.winXinAppID = wxInfo.data.appId;
    this.winXinAppEnv = wxInfo.data.appEnv;

    const openid = getLocal('openid')
    if(!openid){
      const local = window.location.href
      const code = getQueryString('code')
      if (code == null || code === '' || code === 'null') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.winXinAppID + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
      }
      const openIDRes = await getOpenID(code)
      setLocal('openid', openIDRes.data);
    }

    console.log(String(localStorage.getItem('openid')))
    console.log(localStorage.getItem('openid'))

    const productInfo = await getProductInfo(uuid)
    console.log('------productInfo-------');

    var product = productInfo.data;
    this.registerInfo.customerName = product.customerName;
    this.registerInfo.customerTel = product.customerTel;

    // this.registerInfo.addressPca = product.provinceName + '/'
    //                              + product.cityName + '/'
    //                              + product.areaName;
    this.registerInfo.provinceCode = product.provinceCode;
    this.registerInfo.provinceName = product.provinceName;
    this.registerInfo.cityCode = product.cityCode;
    this.registerInfo.cityName = product.cityName;
    this.registerInfo.areaCode = product.areaCode;
    this.registerInfo.areaName = product.areaName;
    this.registerInfo.streetCode = product.streetCode;
    this.registerInfo.streetName = product.streetName;
    this.registerInfo.address = product.address;

    this.product.brandDesc = product.brandDesc;
    this.product.hasRegisted = product.hasRegisted;
    this.product.registeDate = product.registeDate;
    this.product.buyDate = product.buyDate;
    this.product.productCode = product.productCode;
    this.product.productDesc = product.productDesc;
    this.product.productTypeDesc = product.productTypeDesc;
    this.product.unifiedRetailPrices = product.unifiedRetailPrices;
    this.product.workCodeInstall = product.workCodeInstall;
    this.product.workCodeRepaire = product.workCodeRepaire;
  },
  methods: {
    async onSubmit () {
      var that = this;
      const params = this.registerInfo;
      const saveResult = await saveAfterService(params);

      if(saveResult.data.needPay == '2'){
        wx.chooseWXPay({
          appId: saveResult.data.appId,
          timestamp: saveResult.data.stamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: saveResult.data.nonceStr, // 支付签名随机串，不长于 32 位
          package: saveResult.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: saveResult.data.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: saveResult.data.signature, // 支付签名
          complete: function (res) {
            if (res.errMsg === 'chooseWXPay:ok') {
              Toast('已支付成功')
              that.registerInfo = {
                asType:'1',
                customerName:'',
                customerTel:'',
                addressPca:'',
                provinceCode:'',
                provinceName:'',
                cityCode:'',
                cityName:'',
                streetCode:'',
                streetName:'',
                address:'',
                buyDate:'',
                srvMemo:''
              }
            } else if (res.errMsg === 'chooseWXPay:cancel') {   // 取消 res.errMsg === 'chooseWXPay:cancel'
              Toast('已取消支付~')
            }
          }
        });
      }else{
        Toast('保存成功')
        that.registerInfo = {
          asType:'1',
          customerName:'',
          customerTel:'',
          addressPca:'',
          provinceCode:'',
          provinceName:'',
          cityCode:'',
          cityName:'',
          streetCode:'',
          streetName:'',
          address:'',
          buyDate:'',
          srvMemo:''
        }
      }
    },
    pageScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      scrollTop > 100 ? this.headerScroll = true : this.headerScroll = false
    },
    onConfirmBuyDate (buyDateValue) {
      this.registerInfo.buyDate = `${buyDateValue.getFullYear()}/${buyDateValue.getMonth() + 1}/${buyDateValue.getDate()}`;
      this.showBuyDate = false;
    },
    onConfirmAddress (addresss) {
      this.registerInfo.addressPca = addresss
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      this.registerInfo.provinceCode = addresss[0].code;
      this.registerInfo.provinceName = addresss[0].name;
      this.registerInfo.cityCode = addresss[1].code;
      this.registerInfo.cityName = addresss[1].name;
      this.registerInfo.areaCode = addresss[2].code;
      this.registerInfo.areaName = addresss[2].name;
      this.registerInfo.streetCode = addresss[2].code + '99';
      this.registerInfo.streetName = '全区';
      this.showAddressArea = false;
    }
  }
}
</script>

<style lang="less" scoped >
  @import '../common/style/mixin';
  .product {
    overflow-x: hidden;

    .product-info {
      background: #fff;
      padding: 10px 10px 10px 20px;
      font-size: 14px;
      border-top: 8px solid #d7d7d7;
      border-bottom: 8px solid #f2f2f2;
      .info-item {
        margin-bottom: 5px;
        label {
          text-align: right;
          display: inline-block;
          color: #999;
          width: 80px;
        }
      }
    }

  }
</style>
