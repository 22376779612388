import axios from '../utils/axios'

/**
 * 获取七牛云token
 * @param {*} params 
 */
export function queryUpToken(params) {
    return axios.get(`/wxh5/common/queryUpToken`, params);
}
/**
 * 根据扫码uuid获取产品信息
 * @param {*} params 
 */
export function getProductInfo(params) {
    return axios.post(`/wxh5/product/getProductInfo`, params);
}
/**
 * 提交产品信息登记
 * @param {*} params 
 */
export function register(params) {
    return axios.post(`/wxh5/product/register`, params);
}
/**
 * 提交我要售后登记
 * @param {*} params 
 */
export function saveAfterService(params) {
    return axios.post(`/wxh5/product/saveAfterService`, params);
}
/**
 * 查询售后记录
 * @param {*} params 
 */
export function listAllAfterService(params) {
    return axios.get(`/wxh5/product/listAllAfterService`, params);
}
/**
 * 查询售后工单详情
 * @param {*} params 
 */
export function queryOrderDetail(params) {
    return axios.post(`/wxh5/product/queryOrderDetail?logId=${params}`);
}
/**
 * 提交产品信息登记
 * @param {*} params 
 */
export function reverseGeo(params) {
    return axios.get(`/wxh5/common/reverseGeo?location=${params}`);
}
/**
 * 提交产品信息登记
 * @param {*} params 
 */
export function getWxConfig(localHref, uuid) {
    return axios.get(`/wxh5/common/getWxConfig?uuid=${uuid}&localHref=${localHref}`);
}
/**
 * 获取微信配置信息
 * @param {*} params 
 */
export function getWxInfo() {
    return axios.get(`/wxh5/common/getWxInfo`);
}
/**
 * 校验是不是通码服务
 * @param {*} params 
 */
export function getQrCodeInfo() {
    return axios.get(`/wxh5/product/getQrCodeInfo`);
}